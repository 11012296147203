export default {
  data: function data() {
    return {
      list: [{
        image: require("@/assets/liquor/b/1.jpg")
      }, {
        image: require("@/assets/liquor/b/2.png")
      }, {
        image: require("@/assets/liquor/b/3.png")
      }]
    };
  }
};
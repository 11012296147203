export default {
  data: function data() {
    return {
      list: [{
        title: "一盒一码",
        c: "一体化RFID电子标签或二维码包装盒，包材厂提前赋码，数据精准对应；",
        icon: require("@/assets/liquor/a/icon1.png")
      }, {
        title: "全程追溯",
        c: "每盒产品可追溯，准确追溯到生产批次、发货经销商；",
        icon: require("@/assets/liquor/a/icon2.png")
      }, {
        title: "防伪验证",
        c: "消费者用手机触碰产品或者扫描产品二维码快速辨别产品真伪，保护企业品牌；",
        icon: require("@/assets/liquor/a/icon3.png")
      }, {
        title: "系统对接",
        c: "与现有WMS系统进行对接，按电商订单和企业订单发货模式，进行扫描发货。",
        icon: require("@/assets/liquor/a/icon4.png")
      }]
    };
  }
};